<template>
  <div>
    <template v-if="fieldType === 'switch'">
      <label
        class="flex flex-row items-center cursor-pointer mb-6 py-3 px-4 border rounded-sm"
      >
        <span class="text-sm text-gray-700 mr-3">
          {{ configuration.title }}
        </span>
        <label class="custom-switch ml-auto">
          <input
            type="checkbox"
            class="custom-switch-input"
            :checked="checked"
            @change="toggleSwitch"
          >
          <span class="custom-switch-label"></span>
        </label>
      </label>
    </template>
    <template v-else>
      <form-group
        :type="formGroupType"
        :name="configuration.name"
        :form="form"
        :value="value"
        :options="options"
        @change="change"
      >
        {{ configuration.title }}
      </form-group>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ConfigurationField',
  props: {
    configuration: {
      type: Object,
      required: true,
    },
    value: {},
    form: {},
  },
  model: {
    event: 'change',
    prop: 'value',
  },
  data() {
    return {
      checked: false,
    };
  },
  computed: {
    fieldType() {
      return this.configuration?.field?.type || 'number';
    },
    formGroupType() {
      switch (this.fieldType) {
        case 'switch':
          return undefined;
        case 'select':
        case 'money':
            return this.fieldType;
        case 'percentage':
        case 'number':
          return 'number';
        default:
          return 'text';
      }
    },
    options() {
      return this.configuration?.field?.options;
    },
    switchOffOption() {
      return this.options?.find((option) => option.value === false || option.value === 'off');
    },
    switchOnOption() {
      return this.options?.find((option) => option.value === true || option.value === 'on');
    },
  },
  mounted() {
    if (this.fieldType === 'switch') {
      this.checked = this.value === this.switchOnOption.value ? true : this.checked;
    }
  },
  methods: {
    change(value) {
      this.$emit('change', value);
    },
    toggleSwitch({ target }) {
      this.$emit('change', target.checked ? this.switchOnOption.value : this.switchOffOption.value);
    },
  },
}
</script>

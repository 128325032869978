<template>
  <div class="bg-blue-200 bg-opacity-25 min-h-full -mx-10 -mt-4 p-10">
    <div v-if="configurations.loading" class="gap-20 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 opacity-25">
      <template v-for="col in 2">
        <div class="col-span-1" :key="col">
          <template v-for="row in 4">
            <div :key="row" class="flex flex-col gap-4 mb-10">
              <skeleton-loader class="rounded-sm" width="100px" />
              <skeleton-loader class="rounded-sm" height="40px" width="100%" />
            </div>
          </template>
          <skeleton-loader class="rounded-sm" height="40px" width="100px" />
        </div>
      </template>
      
    </div>
    <form @submit.prevent="submit" v-else>
      <div class="gap-10 grid grid-cols-1 mb-20 md:grid-cols-2 xl:grid-cols-3">
        <template v-for="(column, columnIndex) in columns">
          <div class="col-span-1 flex flex-col gap-10" :key="columnIndex">
            <template v-for="(group, groupIndex) in column.groups">
              <div
                :key="groupIndex"
                class="bg-white rounded p-10 col-span-1"
                style="box-shadow: 0 10px 30px rgba(0,0,0, .05)"
              >
                <h6 class="mb-3">
                  {{ group.title }}
                </h6>
                <p class="mb-12 text-gray-700">{{ group.description }}</p>

                <div v-if="group.show">
                  <template v-for="(configuration, configurationIndex) in group.configurations">
                    <Field
                      :key="configurationIndex"
                      :configuration="configuration"
                      v-model="form.data[configuration.name].value"
                      :form="form"
                    />
                  </template>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>

      <div class="flex gap-5">
        <button
          type="button"
          class="btn btn-gray-soft"
          @click="reset"
        >
          <i class="inline-flex mr-3 text-xl">
            <ion-icon name="reload-outline"></ion-icon>
          </i>
          <span>Reset</span>
        </button>
        <button type="submit" class="btn btn-green">
          <i class="inline-flex mr-3 text-xl">
            <ion-icon name="checkmark-outline"></ion-icon>
          </i>
          <span>Save Changes</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Field from './Field.vue';

export default {
  name: 'Configurations',
  components: {
    Field,
  },
  data() {
    return {
      configurations: this.$options.resource([]),
      form: null,
    }
  },
  computed: {
    columns() {
      const maxColumns = 3;
      const columns = [];

      for (let i = 0; i < maxColumns; i++) {
        columns.push({
          groups: this.groups.filter((group, index) => index % maxColumns === i)
        });
      }

      return columns;
    },
    groups() {
      const groups = [];

      this.configurations.data.forEach((configuration) => {
        const configurationGroup = { ...configuration.group } || { title: 'Miscellaneous', name: 'misc', description: 'Other Settings' };
        let group = groups.find((group) => group.name === configurationGroup.name);

        if (!group) {
          group = configurationGroup;
          groups.push(group);
        }

        group.configurations = group.configurations || [];
        group.show = true;
        group.configurations.push(configuration);
      });

      return groups;
    },
  },
  beforeMount() {
    this.getConfiguration();
  },
  methods: {
    toggleGroup(group) {
      group.show = false;
      console.log(group);
    },
    async getConfiguration() {
      this.configurations.loading = true;
      await this.$get({
        url: `${this.$baseurl}/configurations`,
        headers: this.headers,
        success: ({ data: { configurations } }) => {
          this.configurations.setValue(configurations);
          this.generateForm();
        },
        error: error => {
          this.configurations.error = error;
          console.log(error);
        }
      });
      this.configurations.loading = false;
    },
    generateForm() {
      const data = JSON.parse(JSON.stringify(this.configurations.data));
      const fields = data.map(({ name, value }) => ({
        name,
        value,
        rules: 'required'
      }));
      this.form = this.$options.basicForm(fields);
    },
    reset() {
      this.generateForm();
    },
    async submit() {
      if (!this.validateForm(this.form)) return false;

      this.form.loading = true;

      await this.$put({
        url: `${this.$baseurl}/configurations`,
        data: this.getFormData(),
        headers: this.headers,
        success: () => {
          this.$success({
            title: 'Operation Successful',
            body: 'Configurations Updated',
            button: {
              text: 'Go to Dashboard',
              onClick: () => this.$router.push({ name: 'dashboard' }),
            },
          });
        },
        error: error => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        }
      });
      this.form.loading = false;
    }
  }
}
</script>